import React, { useContext, useEffect, useMemo, useState } from 'react';
import DegreeAuditContext, { DegreeAuditState } from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';
import { useNavigate } from 'react-router-dom';
import { useDegreeAuditQuery } from 'src/hooks/degree-audit';
import { DegreeAudit } from 'src/types/degree-audit';
import { UNAUTHORIZED } from 'src/routes/routemap';

interface Props {
  children: React.ReactNode;
}

function DegreeAuditProvider({ children }: Props) {
  const { enrollment } = useContext(EnrollmentContext);
  const navigate = useNavigate();
  const query = useDegreeAuditQuery();

  const [degreeAudit, setDegreeAudit] = useState<DegreeAudit>();

  const currentTerm = useMemo(() => {
    if (!degreeAudit || !enrollment || !Array.isArray(degreeAudit.terms)) {
      return;
    }
    const term = degreeAudit.terms.find(
      (t) => t.termId === enrollment.currentTerm
    );
    if (!term) {
      navigate(UNAUTHORIZED);
      return;
    }
    term.enrolledElectives = term.electives.length;
    term.neededElectives = term.electivePlaceHolders.length;
    return term;
  }, [enrollment?.currentTerm, degreeAudit?.terms]);

  useEffect(() => {
    // Refetch if the enrollment changes.
    if (enrollment?.enrollmentId) {
      query.refetch();
    }
  }, [enrollment]);

  useEffect(() => {
    if (!query.isSuccess || !query.data?.data) {
      return;
    }
    // TODO: Use Zod for data validation.
    setDegreeAudit(query.data.data);
  }, [query.status, query.data]);

  const ctx: DegreeAuditState = {
    degreeAudit,
    currentTerm,
  };

  return (
    <DegreeAuditContext.Provider value={ctx}>
      {children}
    </DegreeAuditContext.Provider>
  );
}

export default DegreeAuditProvider;
