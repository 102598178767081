import { dateFormatMMddyyyy } from './format';
import { AccountStatus, Enrollment } from 'src/types/enrollment';
import { NextPaymentData, ScheduledPayment } from 'src/types/payments';
import { getProgramName } from './get-program-name';
import { cms } from './language';

export const getPaymentEnrollment = (
  scheduledPayments: ScheduledPayment[] | undefined,
  enrollments: Enrollment[] | undefined,
  defaultEnrollment: Enrollment | undefined
): Enrollment | undefined => {
  if (!scheduledPayments || scheduledPayments?.length === 0) {
    return defaultEnrollment;
  }
  return enrollments?.find((e) => e.program?.programName === scheduledPayments[0].programName && !e.accountStatus.paymentsBlocked) ?? defaultEnrollment;
};

export const getUxStatus = (accountStatus: AccountStatus): string => {
  const code = accountStatus?.code;
  let uxStatus: string;
  switch (code) {
    case 'CURRENT':
      uxStatus = 'current';
      break;
    case 'INCOLL':
      uxStatus = 'suspended';
      break;
    case 'PIF':
      uxStatus = 'paid';
      break;
    case 'PASTDUE':
      uxStatus = 'pastdue';
      break;
    case 'COLLRDY':
      uxStatus = 'suspended';
      break;
    case 'ARREARS':
      uxStatus = 'suspended';
      break;
    case 'BANKRUPT':
      uxStatus = 'deactivated';
      break;
    default:
      uxStatus = 'current';
      break;
  }
  return uxStatus;
};

export const paidDescription = 'Your payment plan is paid. You have no scheduled payments at this time.';
export const paidStatus = 'paid';

export const getPaymentDescription = (
  status: string,
  nextPaymentData: NextPaymentData,
  enrollment?: Enrollment
): string => {
  let description = '';
  const programName = (enrollment && getProgramName(enrollment)) ?? nextPaymentData?.programName ?? '';
  const dueDate =
    nextPaymentData?.dueDate instanceof Date
      ? nextPaymentData.dueDate.toISOString().split('T')[0]
      : new Date(nextPaymentData?.dueDate).toISOString().split('T')[0];
  const formattedDueDate = dateFormatMMddyyyy(dueDate);
  const content = cms.content.pages.dashboard.cards.my_payments;
  const { replaceVariables } = cms;

  switch (status) {
    case 'suspended':
      description = replaceVariables(content.payment_description_suspended, [
        { key: 'programName', value: programName },
        { key: 'formattedDueDate', value: formattedDueDate },
      ]);
      break;
    case 'current':
      if (nextPaymentData.paymentScheduleType !== 'fee') {
        if (nextPaymentData.paymentPlanType !== 'standard') {
          // description = `Your payment plan is current. Your next payment for ${programName} will be automatically processed on ${formattedDueDate}`;
          description = replaceVariables(content.payment_description_current_auto, [
            { key: 'programName', value: programName },
            { key: 'formattedDueDate', value: formattedDueDate },
          ]);
        } else {
          description = replaceVariables(content.payment_description_current_standard, [
            { key: 'programName', value: programName },
            { key: 'formattedDueDate', value: formattedDueDate },
          ]);
        }
      } else {
        description = replaceVariables(content.payment_description_current_fee, [
          { key: 'programName', value: programName },
          { key: 'formattedDueDate', value: formattedDueDate },
        ]);
      }

      break;
    case 'pastdue':
      if (nextPaymentData.paymentScheduleType !== 'fee') {
        description = replaceVariables(content.payment_description_past_due, [
          { key: 'programName', value: programName },
          { key: 'formattedDueDate', value: formattedDueDate },
        ]);
      } else {
        description = replaceVariables(content.payment_description_past_due_fee, [
          { key: 'programName', value: programName },
          { key: 'formattedDueDate', value: formattedDueDate },
        ]);
      }

      break;
    case 'paid':
      description = replaceVariables(content.payment_description_paid, [{ key: 'programName', value: programName }]);
      break;
    case 'deactivated':
      description = replaceVariables(content.payment_description_deactivated, [
        { key: 'programName', value: programName },
      ]);
      break;
    default:
      description = content.payment_description_default;
      break;
  }
  return description;
};
