import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import ElectivesView, { ElectiveViewProps } from 'src/components/electives';
import { LoadingStateFailedModal, UserNotFoundModal } from 'src/components/error-modals';
import DegreeAuditContext from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { useDegreeAuditQuery } from 'src/hooks/degree-audit';
import { useElectivesCalculations, useElectivesQuery } from 'src/hooks/electives';

import { ElectivesLoading } from './electives-loading';

export default function Electives() {
  const { user, ...userContext } = useContext(UserContext);
  const { enrollment, ...enrollmentCtx } = useContext(EnrollmentContext);
  const { degreeAudit, currentTerm } = useContext(DegreeAuditContext);

  const degreeAuditQuery = useDegreeAuditQuery();
  const electivesQuery = useElectivesQuery(currentTerm);
  const { nNeededElectives, electivePools } = useElectivesCalculations();
  const [component, setComponent] = useState<ReactElement>(<></>);
  const [errorState, setErrorState] = useState<boolean>(false);
  /** This Error handling is ultimately redundant. If the enrollment call fails the whole app will not load much further up the heirarchy. */
  const [criticalError, setCriticalError] = useState<boolean>(false);

  const queriesStatus = useMemo(() => {
    return [enrollmentCtx.queryStatus, degreeAuditQuery.status, userContext.queryStatus, electivesQuery.status];
  }, [enrollmentCtx.queryStatus, degreeAuditQuery.status, userContext.queryStatus, electivesQuery.status]);

  useEffect(() => {
    electivesQuery.refetch();
  }, []);

  useEffect(() => {
    if (!errorState && degreeAudit && currentTerm) {
      if (queriesStatus.includes('loading')) {
        setComponent(<ElectivesLoading />);
      } else if (queriesStatus.includes('error')) {
        setErrorState(true);
      } else if (!enrollment || !user) {
        setCriticalError(true);
      } else if (electivesQuery.data) {
        // Check if the electives data is available
        const props: ElectiveViewProps = {
          currentTerm,
          electivePools,
          nNeededElectives,
        };

        setComponent(<ElectivesView {...props} />);
      }
    }
  }, [queriesStatus, degreeAudit, electivesQuery.data]);

  useEffect(() => {
    if (errorState && !criticalError) setComponent(<LoadingStateFailedModal />);
    if (criticalError) setComponent(<UserNotFoundModal />);
  }, [errorState, criticalError]);

  return component;
}
