import { Box, CardActions, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { Alert, Button, Card } from '@pennfoster/pfc-design-system';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { updateUsername } from 'src/api/user';
import { AxiosError } from 'axios';
import { getErrorMessage } from 'src/helpers/handling-errors';
import ReactJson from 'react-json-view';
import { usernameChangeAtom, initialUsernameChangeState } from 'src/state';
import { useAtom } from 'jotai';
import { usernameChange } from 'src/types/username-change';

export function AdminUsernameChange() {
  const [userAccounts, setUserAccounts] = useState<null | { email: string, password: string }[]>(null);
  const [errors, setErrors] = useState<null | string>(null);
  const [usernameChange, setUsernameChange] = useAtom(usernameChangeAtom)
  
  const handleChange = (key: keyof usernameChange, value: string) => {
    setUsernameChange((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const updateUsernameMutation = useMutation({
    mutationFn: (data: usernameChange) => updateUsername(data),
    onSuccess: ({ data }) => {
      setUserAccounts(data.userAccounts)
    },
    onError: (error: AxiosError) => {
      const errorMessage = getErrorMessage(error);
      setErrors(errorMessage);
    },
  });

  const handleSubmit = async () => {
    updateUsernameMutation.mutate(usernameChange)
  };

  const resetForm = async () => {
    setErrors(null)
    setUserAccounts(null)
    setUsernameChange(initialUsernameChangeState)
  };
  
  return (
    <Box p={3}>
      {errors && (
        <Grid item xs={12}>
          <Alert severity='error' pfVariant='standard'>{errors}</Alert>
        </Grid>
      )}
      <Grid container>
          <Card sx={{ pl: 3, pr: 3, pb: 3, maxWidth: 448 }}>
            <Box
              sx={{ pt: 3, pb: 2, pl: 0, pr: 0 }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography component={'span'} variant={'subtitle1'}>
                {' '}
                Username Change{' '}
              </Typography>
            </Box>
            <Divider />
            <CardContent
              sx={{
                pt: 2,
                pb: 0,
                pl: 0,
                pr: 0,
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ pb: 2}}
                    fullWidth
                    id="requested-email"
                    label="Student Number"
                    variant="outlined"
                    onChange={(e) => handleChange('studentNumber', e.target.value)}
                    value={usernameChange.studentNumber}
                  />
                  <TextField
                    fullWidth
                    id="student-number-a"
                    label="Desired Sign in Name"
                    variant="outlined"
                    onChange={(e) => handleChange('desiredUsername', e.target.value)}
                    value={usernameChange.desiredUsername}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ display: 'flex', mt: 1, flexWrap: 'wrap' }}>
                <Button pfVariant={'filled'} onClick={resetForm}>
                  Reset Form{' '}
                </Button>
                <Button pfVariant={'filled'} onClick={handleSubmit}>
                  Submit{' '}
                </Button>
            </CardActions>
            {userAccounts && (
                <Box mt={3} rowGap={2} display={'flex'} flexWrap={'wrap'} width={'100%'} flexDirection={'column'}>
                  <Typography variant="subtitle1">Successfully submitted</Typography>
                  <Divider sx={{ width: '100%' }} />
                  {userAccounts.map((user: any, index: number) =>
                    <ReactJson key={index} src={user!} name={`user ${index + 1}`} collapsed={true} />
                  )}
                </Box>
              )}
              
          </Card>
        </Grid>
    </Box>
  );
}
